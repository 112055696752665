import {
  ReactNode, InputHTMLAttributes, useState, useEffect, useContext,
} from 'react';
import { Field } from 'react-final-form';
import Icon from '@mdi/react';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import MetaErrorHandler from './meta-error-handler';
import { ThemeContext } from '../../contexts/theme-context';

export interface FormFieldPasswordProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode,
  name: string,
  blockClassName?: string
  initialValue?: string,
  autoComplete?: 'current-password' | 'new-password',
}

const FormFieldPassword = ({
  label,
  name,
  initialValue,
  blockClassName = 'block',
  autoComplete,
  ...inputProps
}: FormFieldPasswordProps) => {
  const { isMobile } = useContext(ThemeContext);

  const [toggleShowPassword, setToggleShowPassword] = useState(false);
  const [inputType, setInputType] = useState('password');

  const handleToggleShowPassword = (e) => {
    e.preventDefault();
    setToggleShowPassword(!toggleShowPassword);
  };

  useEffect(() => {
    if (toggleShowPassword) {
      setInputType('text');
    } else {
      setInputType('password');
    }
  }, [toggleShowPassword]);

  const showPasswordToggleSize = isMobile ? 'h-[34px]' : 'h-[38px]';

  return (
    <Field name={name} initialValue={initialValue}>
      {({ input, meta }) => (
        <label className={blockClassName}>
          <span
            className="label-md text-text-color-high-em
        dark:text-text-color-high-em-dark"
          >
            {label}
          </span>
          <div className="flex items-center">
            <input
              type={inputType}
              autoComplete={autoComplete}
              className="mt-2 block w-11/12 text-xs sm:text-sm rounded-l-md bg-light shadow-sm
           border-background-stroke
           dark:border-background-stroke-dark
           dark:text-text-color-low-em
           dark:bg-background-main-dark focus:border-primary-light
           focus:bg-white focus:ring-0"
              {...input}
              {...inputProps}
            />
            <button
              type="button"
              onClick={handleToggleShowPassword}
              className={`relative top-1 float-right cursor-pointer
              bg-background-stroke dark:bg-background-stroke-dark
                rounded-r-md w-10 flex justify-center items-center ${showPasswordToggleSize}`}
              tabIndex={-1}
            >
              <Icon path={toggleShowPassword ? mdiEye : mdiEyeOff} size={0.8} />
            </button>
          </div>
          <MetaErrorHandler meta={meta} />
        </label>
      )}
    </Field>
  );
};

export default FormFieldPassword;
