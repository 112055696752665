import {
  useMemo,
  useState,
  useContext,
} from 'react';
import { NavbarThemeText } from '@app/components/navbar-theme';
import TabHeader from '@app/components/tab-header';
import LoginForm from '@app/forms/login';
import Image from 'next/image';
import { extractedTailwindTheme } from '@app/lib/helpers';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import dynamic from 'next/dynamic';
import { Carousel } from 'react-responsive-carousel';
import { useRouter } from 'next/router';
import { ThemeContext } from '@app/contexts/theme-context';

export interface RegisterLoginProps {
  tab: string
}

export interface CarouselImgProps {
  imgPath: string
}

const customDot = (
  onClickHandler: (e) => void,
  isSelected: boolean,
  index: number,
  label: string,
) => (
  <li
    className="inline-block mr-3 cursor-pointer"
    onClick={onClickHandler}
    onKeyDown={onClickHandler}
    value={index}
    key={index}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
    role="button"
    tabIndex={0}
    aria-label={`${label} ${index + 1}`}
  >
    <div
      style={{
        backgroundColor: isSelected
          ? extractedTailwindTheme.colors.secondary.DEFAULT
          : 'gray',
      }}
      className="h-[8px] w-[8px] rounded-full"
    />
  </li>
);

const DynamicRegisterForm = dynamic(() => import('@app/forms/register'), {
  ssr: false,
});

const RegisterLogin = ({ tab }: RegisterLoginProps) => {
  const { theme } = useContext(ThemeContext);
  const { query, replace } = useRouter();
  const { tab: tabQueryKey = tab } = query;

  const [currentTabKey, setCurrentTabKey] = useState(tabQueryKey);
  const [slideInterval, setSlideInterval] = useState(6000);

  const handleTabChange = (key) => {
    setCurrentTabKey(key);
    replace(key);
  };

  const CarouselImg = ({ imgPath }: CarouselImgProps) => (
    <div data-interval={5000} className="pb-12">
      <Image priority src={imgPath} height="353" width="588" layout="responsive" alt="SimpleWaste" />
    </div>
  );

  const onChange = (index, item) => {
    setSlideInterval(item.props['data-interval']);
  };

  const TAB_OPTIONS = [
    {
      title: 'Login',
      key: 'login',
      component: (
        <div>
          <p className="text-text-color-high-em dark:text-text-color-high-em-dark h3 pb-6">
            Let&apos;s log in to your account
          </p>
          <LoginForm />
        </div>
      ),
    },
    {
      title: 'Sign Up',
      key: 'register',
      component: (
        <div>
          <p className="text-text-color-high-em dark:text-white h3 pb-6">
            Create an Account
          </p>
          <DynamicRegisterForm />
        </div>
      ),
    },
  ];

  const activeTab = useMemo(
    () => TAB_OPTIONS.find((t) => t.key === currentTabKey) || TAB_OPTIONS[0],
    [currentTabKey],
  );

  const LoginIntroTextMobile = () => (
    <div className="space-y-6 pb-3">
      <div className="h2 text-primary">
        <p>
          Win more contracts. Never miss a bid.
        </p>
      </div>
    </div>
  );

  const RegisterIntroTextMobile = () => (
    <div className="space-y-6 pb-3">
      <div className="h2 text-primary">
        <p>
          Welcome to Simplewaste Bidding!
        </p>
      </div>
    </div>
  );

  const CheckLight = () => (
    <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411
        27.9411 36 18 36C8.05888 36 0 27.9411 0 18Z"
        fill="#F0FDF4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6445 11.0855L14.9045 21.4505L12.0545 18.4055C11.5295 17.9105 10.7045
        17.8805 10.1045 18.3005C9.51951 18.7355 9.35451 19.5005 9.71451 20.1155L13.0895
        25.6055C13.4195 26.1155 13.9895 26.4305 14.6345 26.4305C15.2495 26.4305 15.8345
        26.1155 16.1645 25.6055C16.7045 24.9005 27.0095 12.6155 27.0095 12.6155C28.3595
        11.2355 26.7245 10.0205 25.6445 11.0705V11.0855Z"
        fill="#16A34A"
      />
    </svg>
  );

  const CheckDark = () => (
    <svg width="25" height="25" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 18C0 8.05888 8.05888 0 18 0C27.9411 0 36 8.05888 36 18C36 27.9411 27.9411
        36 18 36C8.05888 36 0 27.9411 0 18Z"
        fill="#158040"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6445 11.0855L14.9045 21.4505L12.0545 18.4055C11.5295 17.9105 10.7045 17.8805
        10.1045 18.3005C9.51951 18.7355 9.35451 19.5005 9.71451 20.1155L13.0895 25.6055C13.4195
        26.1155 13.9895 26.4305 14.6345 26.4305C15.2495 26.4305 15.8345 26.1155 16.1645
        25.6055C16.7045 24.9005 27.0095 12.6155 27.0095 12.6155C28.3595 11.2355 26.7245 10.0205
        25.6445 11.0705V11.0855Z"
        fill="#F0FDF4"
      />
    </svg>
  );

  const CheckCircle = () => (
    theme !== 'dark' ? <CheckLight /> : <CheckDark />
  );

  const LoginIllustrations = () => (
    <Carousel
      showArrows={false}
      autoPlay
      infiniteLoop
      swipeable
      showStatus={false}
      showThumbs={false}
      transitionTime={1000}
      interval={slideInterval}
      onChange={onChange}
      renderIndicator={customDot}
    >
      <div className="text-left">
        <div className="space-y-6 pb-12">
          <div className="h2 text-primary">
            <p>
              Bidding has never been
            </p>
            <p>
              this simple.
            </p>
          </div>
          <div className="space-y-4 h6">
            <span className="flex items-center space-x-2">
              <CheckCircle />
              <p>Sign Up</p>
            </span>
            <span className="flex items-center space-x-2">
              <CheckCircle />
              <p>Bid</p>
            </span>
            <span className="flex items-center space-x-2">
              <CheckCircle />
              <p>Done</p>
            </span>
          </div>
        </div>
        <CarouselImg imgPath="/images/swbid-register.png" />
      </div>
      <div className="text-left">
        <div className="space-y-6 pb-12">
          <div className="h2 text-primary">
            <p>
              Win more contracts.
            </p>
            <p>
              Never miss a bid.
            </p>
          </div>
          <p className="h6">
            By becoming a preferred vendor you get access to more bid
            opportunities to grow your customer portfolio.
          </p>
        </div>
        <CarouselImg imgPath="/images/swbid-login-1.png" />
      </div>
      <div className="text-left">
        <div className="space-y-6 pb-12">
          <div className="h2 text-primary">
            <p>
              Easy. Transparent.
            </p>
            <p>
              Always Available.
            </p>
          </div>
          <p className="h6">
            Say goodbye to lengthy email chains, messy spreadsheets, and back-and-forth phone calls.
            Customized notification alerts ensure you never miss a bid opportunity in your service area.
          </p>
        </div>
        <CarouselImg imgPath="/images/swbid-login-2.png" />
      </div>
      <div className="text-left">
        <div className="space-y-6 pb-12">
          <div className="h2 text-primary">
            <p>
              Save Time & Money.
            </p>
            <p>
              Bidding Made Easy!
            </p>
          </div>
          <p className="h6">
            Use the My Bids dashboard to track the status and details of all submitted bids.
            It&apos;s easy to search bids, submit a message, or provide an update. Notification
            alerts will let you know anytime there is a change.
          </p>
        </div>
        <CarouselImg imgPath="/images/swbid-login-3.png" />
      </div>
    </Carousel>
  );

  const RegisterIllustrations = () => (
    <div>
      <div className="space-y-6 pb-12">
        <div className="h2 text-primary">
          <p>
            Welcome to
          </p>
          <p>
            Simplewaste Bidding!
          </p>
        </div>
        <p className="h6">
          Simple is better. Seamlessly manage all aspects of your bidding process in one place.
          Search, bid, track your status, & ask a question, anytime from anywhere. It’s that easy!
        </p>
      </div>
      <img src="/images/swbid-register.png" alt="Welcome to SimpleWaste!" />
    </div>
  );

  return (
    <div className="flex justify-center py-2 min-h-screen lg:pt-20 2xl:pt-24 xl:px-10 2xl:px-0">
      <div className="lg:hidden px-3 py-3 w-screen xs:w-auto">
        <div className="flex justify-center flex-col px-7 py-3">
          <div className="flex flex-row justify-center pb-5">
            <div className="hidden xs:block">
              <Image src="/images/logo.png" height="70" width="100" alt="Welcome to SimpleWaste!" />
            </div>
            <div className="xs:hidden">
              <Image src="/images/logo.png" height="35" width="45" layout="fixed" alt="Welcome to SimpleWaste!" />
            </div>
            <div className="flex flex-row -mt-1 xs:-mt-0 items-center text-2xl xs:text-3xl md:text-4xl">
              <p>SIMPLE</p>
              <p className="font-bold">WASTE</p>
            </div>
          </div>
          {activeTab.key === 'login' ? <LoginIntroTextMobile /> : <RegisterIntroTextMobile />}
        </div>
        <div className="bg-background-low-em shadow-md dark:bg-background-low-em-dark px-3 xs:px-10 rounded-2xl">
          <div className="xl:px-6 pt-5">
            <TabHeader
              tabs={TAB_OPTIONS.filter((t) => t.key !== 'edit-profile')}
              currentTab={activeTab.key}
              onChangeTab={(key) => handleTabChange(key.key)}
              isLoginPage
            />
          </div>
          <div className="pb-2">
            {activeTab.component}
            <div className="flex justify-center">
              <NavbarThemeText />
            </div>
          </div>
        </div>
      </div>
      <div className="xl:max-w-screen-xl hidden lg:block max-w-screen-md mb-10">
        <div className="grid grid-cols-2 xl:gap-x-10 gap-x-5 content-center">
          <div className="mt-12">
            <div className="flex flex-col px-5">
              <div className="flex items-center pb-5 space-x-1">
                <img src="/images/logo.png" height="30" width="40" alt="Welcome to SimpleWaste!" />
                <p>
                  SIMPLE
                  <span className="font-semibold">WASTE</span>
                </p>
              </div>
              {activeTab.key === 'login' ? <LoginIllustrations /> : <RegisterIllustrations />}
            </div>
          </div>
          <div>
            <div className="bg-background-low-em dark:bg-background-low-em-dark shadow-md
             rounded-[32px] max-w-lg"
            >
              <div className="xl:px-6 mr-12 pt-6">
                <TabHeader
                  tabs={TAB_OPTIONS.filter((t) => t.key !== 'edit-profile')}
                  currentTab={activeTab.key}
                  onChangeTab={(key) => handleTabChange(key.key)}
                  isLoginPage
                />
              </div>
              <div className="px-12 p-5">
                {activeTab.component}
                <div className="flex justify-center">
                  <NavbarThemeText />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterLogin;
