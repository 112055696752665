import ModalToggle from '@app/components/modal-toggle';
import SocialButton from '@app/components/social-button';
import { FORGOT_PASSWORD_PAGE, HOME_PAGE } from '@app/constants/routes';
import { useThemeContext } from '@app/contexts/theme-context';
import { signIn } from 'next-auth/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';
import FormFieldInput from './fields/input';
import FormFieldPassword from './fields/password';

export const validate = (values) => {
  const errors = {};

  if (!values.email) {
    Object.assign(errors, { email: 'Email must not be empty' });
  }

  if (!values.password) {
    Object.assign(errors, { password: 'Password must not be empty' });
  }

  return errors;
};

const DynamicSocialButton = dynamic(() => import('@app/components/social-button'), {
  ssr: false,
});

const LoginForm = () => {
  const { query, push: routerPush } = useRouter();
  const callbackUrl = query.callbackUrl as string || HOME_PAGE;
  const { isMobile } = useThemeContext();

  const handleOnSubmit = async (payload, form) => {
    const { email, password } = payload;

    const credentials = {
      email: email.toLowerCase(),
      password,
    };

    const { error } = await signIn('credentials', { redirect: false, ...credentials });

    if (error) {
      // reset password field
      form.change('password', undefined);

      toast.error('Invalid login');

      return { password: 'Oops! User doesn\'t seem to exist. Please try again.' };
    }

    toast.success('Succesfully logged in');
    return routerPush(callbackUrl);
  };

  const renderSocialLoginButtons = (
    <Fragment>
      <SocialButton
        title="SimpleWaste"
        onClick={() => signIn('simplewaste', { redirect: false, callbackUrl })}
        imgSrc="/assets/svgs/brands/logo-mark.svg"
        fromLogin
      />
      <SocialButton
        onClick={() => signIn('google', { callbackUrl, redirect: false }, { prompt: 'login' })}
        title="Google"
        fromLogin
        imgSrc="/assets/svgs/brands/google.svg"
      />
      <SocialButton
        onClick={() => signIn('facebook', { redirect: false, callbackUrl }, { prompt: 'login' })}
        title="Facebook"
        fromLogin
        imgSrc="/assets/svgs/brands/facebook.svg"
      />
      <SocialButton
        onClick={() => signIn('azure-ad', { redirect: false, callbackUrl }, { prompt: 'login' })}
        title="Office 365"
        fromLogin
        imgSrc="/assets/svgs/brands/office365.svg"
      />
    </Fragment>
  );

  const renderModal = (
    <div className="grid grid-cols-1 gap-5">
      <h3 className="text-xl font-bold mb-5">
        Sign In via other platforms
      </h3>
      {renderSocialLoginButtons}
      <DynamicSocialButton disabled title="Apple" imgSrc="/assets/svgs/brands/apple.svg" />
      <DynamicSocialButton
        disabled
        title="AOL"
        fromLogin
        imgSrc="/assets/svgs/brands/aol.svg"
      />
      <DynamicSocialButton
        disabled
        title="Yahoo"
        fromLogin
        imgSrc="/assets/svgs/brands/yahoo.svg"
      />
    </div>
  );

  return (
    <div>
      <Form
        onSubmit={handleOnSubmit}
        validate={validate}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-3 text-sm mb-3">
              <FormFieldInput name="email" label="Email" type="email" required />
              <FormFieldPassword
                name="password"
                autoComplete="current-password"
                label={(
                  <div className="flex items-center justify-between">
                    <span className="text-text-color-high-em
                dark:text-text-color-high-em-dark font-medium"
                    >
                      Password
                    </span>
                    <Link href={FORGOT_PASSWORD_PAGE}>
                      <span className="text-primary hidden xs:block font-bold cursor-pointer text-xs" tabIndex={-1}>
                        Forgot Password ?
                      </span>
                    </Link>
                  </div>
                )}
                required
              />
              <Link href={FORGOT_PASSWORD_PAGE}>
                <span className="text-primary xs:hidden -mt-1 font-bold cursor-pointer text-xs" tabIndex={-1}>
                  Forgot Password ?
                </span>
              </Link>
            </div>
            <button
              type="submit"
              className="bg-primary hover:bg-primary text-white font-medium py-2 px-6 sm:px-10 border
              border-primary rounded-2xl disabled:opacity-50 disabled:cursor-not-allowed w-full"
              disabled={submitting}
            >
              {submitting ? 'Signing in..' : 'Log In'}
            </button>
            <div className="grid grid-cols-1 gap-5 text-center text-gray-400 mt-5 text-sm">
              <div className="flex flex-row items-center">
                <div className="h-px w-full bg-background-stroke" />
                <p className="px-5">or</p>
                <div className="h-px w-full bg-background-stroke" />
              </div>
              <p className="xs:hidden">Continue with...</p>
              {renderSocialLoginButtons}
              <ModalToggle
                hasCloseButton
                renderedComponent={renderModal}
                overrideStyles={{ content: { width: isMobile ? '90vw' : 'auto' } }}
              >
                {(setModalOpen) => (
                  <button
                    type="button"
                    className="text-gray-500 cursor-pointer text-xs"
                    onClick={() => setModalOpen(true)}
                  >
                    Click here to view more sign in options
                  </button>
                )}
              </ModalToggle>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default LoginForm;
