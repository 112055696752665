import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import { useEffect, useRef, useState } from 'react';

export type tabHeaderTypes = {
  title: string;
  key: string;
  // This will allow any additional properties as long as
  // the above 2 are there
  [propName: string]: any;
};
export interface TabHeaderProps<T> {
  tabs: T[];
  currentTab: string;
  onChangeTab: (t: T) => void;
  type?: 'primary' | 'secondary';
  isLoginPage?: boolean;
}

const TabHeader = <T extends tabHeaderTypes>({
  tabs,
  currentTab,
  onChangeTab,
  type = 'primary',
  isLoginPage,
}: TabHeaderProps<T>) => {
  const [isLastItemVisible, setIsLastItemVisible] = useState<boolean>(false);
  const [isFirstItemVisible, setIsFirstItemVisible] = useState<boolean>(false);
  const initialPositionRef = useRef(null);
  const tabStyle = `flex justify-between items-center text-center mb-6 sm:ml-6 md:ml-0 
  lg:ml-6 w-full xl:w-1/2 h-[38px] rounded-lg`;
  const tabClassName = `${tabStyle} bg-background-mid-em ${isLoginPage ? 'dark:bg-background-stroke-dark'
    : 'dark:bg-background-disabled-dark'}`;

  const baseClassName = 'cursor-pointer w-full m-[3px]';
  const activeStyle = 'text-text-color-light bg-primary-light h-8 rounded-lg drop-shadow';
  const activeClassName = `${activeStyle} flex justify-center items-center font-semibold`;
  const inActiveClassName = 'text-text-color-high-em dark:text-text-color-light font-semibold';

  useEffect(() => {
    if (!initialPositionRef.current) return;
    const lastItemVisible = (initialPositionRef.current.scrollWidth
      - initialPositionRef.current.scrollLeft
      - initialPositionRef.current.clientWidth)
      < 100;
    const firstItemVisible = initialPositionRef.current.scrollLeft < 50;
    setIsLastItemVisible(lastItemVisible);
    setIsFirstItemVisible(firstItemVisible);
  }, [initialPositionRef.current?.scrollLeft]);

  const handleHorizontalScroll = (e) => {
    const lastItemVisible = (e.target.scrollWidth - e.target.scrollLeft - e.target.clientWidth) < 100;
    const firstItemVisible = e.target.scrollLeft < 50;
    setIsFirstItemVisible(firstItemVisible);
    setIsLastItemVisible(lastItemVisible);
  };

  return type === 'primary' ? (
    <ul className={`${tabClassName}`}>
      {tabs.map((t) => (
        <li
          key={t.key}
          onClick={() => onChangeTab(t)}
          className={`${baseClassName} ${currentTab === t.key ? activeClassName : inActiveClassName
          }`}
          role="presentation"
        >
          <p className="text-sm">{t.title}</p>
        </li>
      ))}
    </ul>
  ) : (
    <div className="flex flex-row items-center border-b-2 border-text-color-mid-em h-[40px]">
      {!isFirstItemVisible && (
        <div className="pt-[2px]">
          <Icon path={mdiChevronLeft} size={1} />
        </div>
      )}
      <div
        className="flex flex-row overflow-x-auto no-scrollbar"
        onScroll={handleHorizontalScroll}
        ref={initialPositionRef}
      >
        {tabs.map((t) => (
          <span
            key={t.key}
            onClick={() => onChangeTab(t)}
            className={`mt-1 ${currentTab === t.key ? 'border-b-4 border-secondary' : ''
            } py-2 px-3`}
            role="presentation"
          >
            <p className="label-md whitespace-nowrap">{t.title}</p>
          </span>
        ))}
      </div>
      {!isLastItemVisible && (
        <div className="pt-[2px]">
          <Icon path={mdiChevronRight} size={1} />
        </div>
      )}
    </div>
  );
};

export default TabHeader;
