import { useState, Fragment, ReactNode } from 'react';
import { Props, Styles } from 'react-modal';
import Button from './button';
import Modal from './modal';

export interface ModalToggleProps extends Omit<Props, 'isOpen'> {
  renderedComponent: ReactNode,
  children: (t: any) => ReactNode,
  hasCloseButton?: boolean,
  initialState?: boolean,
  overrideStyles?: Styles,
}

const ModalToggle = ({
  children, initialState = false, renderedComponent, hasCloseButton = false, overrideStyles, ...modalProps
}: ModalToggleProps) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const renderCloseButton = hasCloseButton && (
    <Fragment>
      {/* Spacing purpose */}
      <div className="h-5" />
      <div className="px-5 py-5">
        <Button
          size="l"
          className="rounded-lg dark:text-white"
          buttonType="secondary"
          onClick={() => setIsOpen(false)}
          full
        >
          Close
        </Button>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        overrideStyles={overrideStyles}
        {...modalProps}
      >
        {renderedComponent}
        {renderCloseButton}
      </Modal>
      {children(setIsOpen)}
    </Fragment>
  );
};

export default ModalToggle;
