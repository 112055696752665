export interface SocialButtonProps {
  title: string,
  imgSrc?: string,
  onClick?: () => void,
  disabled?: boolean,
  fromLogin?: boolean,
}

const SocialButton = ({
  title, imgSrc, onClick, disabled = false, fromLogin = false,
}: SocialButtonProps) => (
  <button
    type="button"
    className={`bg-light shadow-md py-2 rounded-xl font-semibold flex items-center justify-center 
    dark:bg-background-main-dark text-primary btn-text-base dark:text-primary w-full
    ${disabled
      && 'disabled:bg-gray-300 dark:bg-background-disabled-dark cursor-not-allowed'
      }
    ${disabled
      && 'dark:!text-gray-700 !text-gray-400'
      }`}
    onClick={onClick}
    disabled={disabled}
  >
    {imgSrc && <img alt={title} src={imgSrc} width={24} height={24} className="h-6 mr-3" />}
    {fromLogin
      ? (
        <p>
          <span className="hidden xs:inline-block">Continue with&nbsp;</span>
          {title}
        </p>
      )
      : (
        <p>
          <span className="hidden xs:inline-block">Sign Up via&nbsp;</span>
          {title}
        </p>
      )}
  </button>
);

export default SocialButton;
